import ExportExcel from '../../../components-soapy/Excel/ExportExcel.jsx'
import { Tooltip } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import Equalizer from '@material-ui/icons/Equalizer'
import React, { useEffect, useState } from 'react'
import ExcelColumn from 'react-export-excel-fixed-xlsx/dist/ExcelPlugin/elements/ExcelColumn'
import { withTranslation } from 'react-i18next'
import Button from '../../../components/CustomButtons/Button.jsx'
import getCookie from '../../../shared/cookie/get-cookie.js'
import exportPDF from '../../../shared/export/exportPDF'

const MaxTemperatureExport = ({ washesChartRef, dateBtnText, isCelsius, t }) => {
  const [headers, setHeaders] = useState([])
  const title = dateBtnText
  const fileName = `${t('Daily Washes')} - ${dateBtnText}`
  const excelColumns = headers.map(name => {
    return <ExcelColumn label={name} key={name} value={name}/>
  })

  const handleExportChart = () => {
    washesChartRef.current.chart.exportChart(
      {
        type: 'application/pdf',
        sourceWidth: 2000,
        sourceHeight: 1000,
        filename: fileName
      }
    )
  }

  const language = getCookie('language')

  useEffect(() => {
    if (washesChartRef.current.chart) {
      setHeaders([t('Day'), ...washesChartRef.current.chart.series.map(serie => serie.name)])
    }
  }, [language, washesChartRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleExportPdf = () => {
    const chart = washesChartRef.current.chart
    const series = chart.series
    const rows = []
    const rowSum = []
    series.forEach((serie, serieIndex) => {
      let sum = 0
      serie.data.forEach((data, rowIndex) => {
        const value = data.y || 0
        sum += value
        if (rows[rowIndex]) {
          rows[rowIndex][serieIndex + 1] = value
        } else {
          rows.push([
            data.category,
            value
          ])
        }
      })
      rowSum.push(sum)
    })
    exportPDF(fileName, '', headers, [...rows, [t('Sum'), ...rowSum]], 'landscape')
  }

  const getExcelRows = () => {
    const chart = washesChartRef.current.chart
    const series = chart.series
    const rows = []
    const rowSum = {}
    series.forEach((serie, serieIndex) => {
      let sum = 0
      serie.data.forEach((data, rowIndex) => {
        const value = data.y || 0
        sum += value
        if (rows[rowIndex]) {
          rows[rowIndex][headers[serieIndex + 1]] = value
        } else {
          rows.push({
            [headers[0]]: data.category,
            [headers[serieIndex + 1]]: value
          })
        }
      })
      rowSum[headers[serieIndex + 1]] = sum
    })

    return [...rows, { [t('Day')]: t('Sum'), ...rowSum }]
  }

  return (
    <div style={{ marginLeft: 'auto' }}>
      {
        washesChartRef
          ? <>
            <Button
              id="trendsExportToChartButton"
              justIcon
              round
              style={{ marginRight: '5px' }}
              color="info"
              onClick={handleExportChart}
            >
              <Tooltip title={t('Export To Chart')}>
                <Equalizer/>
              </Tooltip>
            </Button>
            <ExportExcel
              fileName={fileName}
              title={title}
              data={getExcelRows}
              columns={excelColumns}
            />

            <Button
              // id='trendsExportToPdfButton'
              justIcon
              round
              style={{ marginLeft: '5px' }}
              color="info"
              onClick={() => {
                handleExportPdf()
              }}
            >
              <Tooltip title={t('Export to pdf')}>
                <Description/>
              </Tooltip>
            </Button>

          </>
          : null
      }

    </div>
  )
}

export default withTranslation()(MaxTemperatureExport)
