import ExportExcel from '../../../components-soapy/Excel/ExportExcel.jsx'
import { Tooltip } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import Equalizer from '@material-ui/icons/Equalizer'
import React from 'react'
import Button from '../../../components/CustomButtons/Button.jsx'
import { withTranslation } from 'react-i18next'
import ExcelColumn from 'react-export-excel-fixed-xlsx/dist/ExcelPlugin/elements/ExcelColumn'
import exportPDF from '../../../shared/export/exportPDF'

const WashesSumExport = ({ washesSumChartRef, dateBtnText, t }) => {
  const headers = [t('Day'), t('Total washes')]
  const fileName = `${t('Washes sum')} - ${dateBtnText}`
  const excelColumns = headers.map(name => {
    return <ExcelColumn label={name} key={name} value={name}/>
  })
  const handleExportChart = () => {
    washesSumChartRef.current.chart.exportChart(
      {
        type: 'application/pdf',
        sourceWidth: 2000,
        sourceHeight: 1000,
        filename: fileName
      }
    )
  }

  const handleExportPdf = () => {
    const chart = washesSumChartRef.current.chart
    const chartData = chart.series[0].data
    let washTotalSum = 0
    const rows = chartData.map(data => {
      washTotalSum += data.total
      return [
        data.category,
        data.total
      ]
    })
    exportPDF(fileName, '', headers, [...rows, [t('Sum'), washTotalSum]], 'landscape')
  }

  const getExcelRows = () => {
    const chart = washesSumChartRef.current.chart
    const chartData = chart.series[0].data
    let washTotalSum = 0

    const rows = chartData.map(data => {
      washTotalSum += data.total
      return {
        [t('Day')]: data.category,
        [t('Total washes')]: data.total
      }
    })
    return [...rows, { [t('Day')]: t('Sum'), [t('Total washes')]: washTotalSum }]
  }

  return (
    <div style={{ marginLeft: 'auto' }}>
      {
        washesSumChartRef
          ? <>
            <Button
              id="trendsExportToChartButton"
              justIcon
              round
              style={{ marginRight: '5px' }}
              color="info"
              onClick={handleExportChart}
            >
              <Tooltip title={t('Export To Chart')}>
                <Equalizer/>
              </Tooltip>
            </Button>
            <ExportExcel
              fileName={fileName}
              title={dateBtnText}
              data={getExcelRows}
              columns={excelColumns}
            />

            <Button
              id="trendsExportToPdfButton"
              justIcon
              round
              style={{ marginLeft: '5px' }}
              color="info"
              onClick={() => {
                handleExportPdf()
              }}
            >
              <Tooltip title={t('Export to pdf')}>
                <Description/>
              </Tooltip>
            </Button>

          </>
          : null
      }

    </div>
  )
}

export default withTranslation()(WashesSumExport)
