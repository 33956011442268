import ExportExcel from '../../../components-soapy/Excel/ExportExcel.jsx'
import { Tooltip } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import Equalizer from '@material-ui/icons/Equalizer'
import React from 'react'
import Button from '../../../components/CustomButtons/Button.jsx'
import ExcelColumn from 'react-export-excel-fixed-xlsx/dist/ExcelPlugin/elements/ExcelColumn'
import exportPDF from '../../../shared/export/exportPDF'
import { withTranslation } from 'react-i18next'

const MaxTemperatureExport = ({ maxTemperatureChartRef, dateBtnText, isCelsius, t }) => {
  const headers = [t('Day'), `${t('Maximum Temperature')} ${isCelsius ? '°C' : '°F'}`]
  const title = dateBtnText
  const fileName = `${t('Daily Max Temperatures')} - ${dateBtnText}`
  const excelColumns = headers.map(name => {
    return <ExcelColumn label={name} key={name} value={name} />
  })
  const handleExportChart = () => {
    maxTemperatureChartRef.current.chart.exportChart(
      {
        type: 'application/pdf',
        sourceWidth: 2000,
        sourceHeight: 1000,
        filename: fileName
      }
    )
  }

  const handleExportPdf = () => {
    const chart = maxTemperatureChartRef.current.chart
    const chartData = chart.series[0].data
    const rows = chartData.map(data => {
      return [
        data.category,
        data.y
      ]
    })
    exportPDF(fileName, headers, rows, 'landscape')
  }

  const getExcelRows = () => {
    const chart = maxTemperatureChartRef.current.chart
    const chartData = chart.series[0].data

    const rows = chartData.map(data => {
      return {
        [headers[0]]: data.category,
        [headers[1]]: data.y
      }
    })
    return rows
  }

  return (
    <div style={{ marginLeft: 'auto' }}>
      {
        maxTemperatureChartRef
          ? <>
            <Button
              id='trendsExportToChartButton'
              justIcon
              round
              style={{ marginRight: '5px' }}
              color='info'
              onClick={handleExportChart}
            >
              <Tooltip title={t('Export To Chart')}>
                <Equalizer />
              </Tooltip>
            </Button>
            <ExportExcel
              fileName={fileName}
              title={title}
              data={getExcelRows}
              columns={excelColumns}
            />
            <Button
              id='trendsExportToPdfButton'
              justIcon
              round
              style={{ marginLeft: '5px' }}
              color='info'
              onClick={() => {
                handleExportPdf()
              }}
            >
              <Tooltip title={t('Export to pdf')}>
                <Description />
              </Tooltip>
            </Button>
          </>
          : null
      }

    </div>
  )
}

export default withTranslation()(MaxTemperatureExport)
