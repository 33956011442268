import ExportExcel from '../../../components-soapy/Excel/ExportExcel.jsx'
import defaultFilterMethod from '../../../shared/react-table/defaultFilterMethod.js'
import addReactTableFilterPlaceholder from '../../../shared/react-table/addReactTableFilterPlaceholder.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import ExcelColumn from 'react-export-excel-fixed-xlsx/dist/ExcelPlugin/elements/ExcelColumn'
import { withTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import disconnectionModalStyle from '../../../assets-soapy/jss/soapy/views/disconnectionModalStyle.jsx'
import Modal from '../../../components-soapy/Modal'
import getLanguageLocale from '../../../shared/multi-language/getLanguageLocale.js'

const DEFAULT_PAGE_SIZE = 10
const LAST_SYNC = 'lastSync'
const CURRENT_SYNC = 'currentSync'

const MOMENT_FORMAT = 'LLL'

const formatDates = (disconnectionLogs) => {
  return disconnectionLogs.map(discLog => {
    return { ...discLog, [LAST_SYNC]: moment(discLog[LAST_SYNC]).locale(getLanguageLocale()).format(MOMENT_FORMAT), [CURRENT_SYNC]: moment(discLog[CURRENT_SYNC]).locale(getLanguageLocale()).format(MOMENT_FORMAT) }
  })
}

const headers = ['from', 'to']

const DisconnectionLogsModal = ({
  isOpen,
  title,
  isLoading,
  onCancel,
  classes,
  disconnectionLogs,
  t
}) => {
  useEffect(() => {
    addReactTableFilterPlaceholder()
  })

  const excelColumns = headers.map(name => {
    return <ExcelColumn label={name} key={name} value={name} />
  })

  const formattedDisconnectionLogs = useMemo(() => formatDates(disconnectionLogs), [disconnectionLogs])
  const getExcelRows = useCallback(
    () => {
      const rows = formattedDisconnectionLogs.map(disconnectionLog => {
        return {
          [headers[0]]: disconnectionLog[LAST_SYNC],
          [headers[1]]: disconnectionLog[CURRENT_SYNC]
        }
      })
      return rows
    },
    [formattedDisconnectionLogs]
  )

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dialogPaper={classes.dialogPaper}
      hideActions
      isLoading={isLoading}
      onCancel={onCancel}
    >

      {isLoading
        ? <CircularProgress
            color='primary'
            style={{ position: 'absolute', top: '50%', left: '50%' }}
          />

        : <div>
          <ExportExcel
            fileName={`Disconnection logs ${new Date()}`}
            title={`Disconnection logs ${new Date()}`}
            data={getExcelRows}
            columns={excelColumns}
          />
          <ReactTable
            data={formattedDisconnectionLogs}
            defaultFilterMethod={defaultFilterMethod}
            columns={[

              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('From')}</span>
                  </div>
                ),
                accessor: LAST_SYNC,
                sortMethod: (a, b) => {
                  const momentA = moment(a)
                  const momentB = moment(b)
                  return momentA.isBefore(momentB) ? -1 : 1
                },
                style: { textAlign: 'left' },
                filterable: true
              },
              {
                Header: () => (
                  <div
                    style={{
                      textAlign: 'left'

                    }}
                  >
                    <span style={{ fontSize: 20 }}>{t('To')}</span>
                  </div>
                ),
                style: { textAlign: 'left' },
                sortMethod: (a, b) => {
                  const momentA = moment(a)
                  const momentB = moment(b)
                  return momentA.isBefore(momentB) ? -1 : 1
                },
                filterable: true,
                accessor: CURRENT_SYNC
              }

            ]}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={[
              {
                id: LAST_SYNC,
                desc: true
              }
            ]}
            minRows={0}
            showPaginationBottom={disconnectionLogs.length > DEFAULT_PAGE_SIZE}
            className='-striped -highlight'
            nextText={t('Next')}
            pageText={t('Page')}
            ofText={t('Of')}
            rowsText={t('Rows')}
            previousText={t('Previous')}
          />
          </div>}

    </Modal>
  )
}

export default withStyles(disconnectionModalStyle)(withTranslation()(DisconnectionLogsModal))
